<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">live session</div>
      </v-row>
      <v-row class="">
        <div class="">
          Please note that listed session times may occasionally be delayed
          without notice. Also, broadcasts are often temporarily suspended for
          recess, commitee meetings, conferences or other legistlative
          activities.
        </div>
      </v-row>
    </v-col>

    <div class="pa-2">
      <v-card>
        <v-card-text>
          <v-row
            class="font-weight-bold text-uppercase d-flex flex-column flex-md-row"
          >
            <v-col>
              <p class="text-uppercase">meeting body</p>
              <div class="d-flex">
                <v-icon left> mdi-account-group </v-icon>
                <p class="my-auto">house</p>
              </div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col>
              <p class="primary--text">about session</p>
              <div class="d-flex">
                <v-icon left> mdi-script-text </v-icon>
                <p class="my-auto">budget</p>
                <v-btn
                  class="ml-2"
                  text
                  color="primary"
                  outlined
                  small
                  rounded
                  @click="pdfDialog = true"
                >
                  <v-icon>mdi-file-pdf-box</v-icon> intro document</v-btn
                >
              </div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col>
              <p class="green--text">started at:</p>
              <div class="d-flex">
                <v-icon left> mdi-clock-outline </v-icon>
                <p class="my-auto">8:30 AM</p>
              </div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col>
              <p class="purple--text">ends at:</p>
              <div class="d-flex">
                <v-icon left> mdi-clock-outline </v-icon>
                <p class="my-auto">10:30 AM</p>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>
          <p class="font-weight-light text-capitalize">
            tap the mic before you begin to speak
          </p>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row class="flex-column flex-sm-row">
            <v-col
              class="d-flex flex-column align-center justify-center"
              cols="2"
            >
              <v-btn
                color="primary"
                fab
                small
                @click="record(), (doneTalking = false)"
              >
                <v-icon> mdi-microphone </v-icon>
              </v-btn>
              <v-btn
                color="primary"
                class="mt-2"
                fab
                small
                @click="doneTalking = true"
              >
                <v-icon> mdi-stop </v-icon>
              </v-btn>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="">
              <v-textarea
                label="Tap the microphone to start recording..."
                readonly
                v-model="runtimeTranscription_"
              ></v-textarea>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col>
              <v-form>
                <p class="text-h6 text-uppercase">voting</p>
                <v-autocomplete
                  label="Vote Type"
                  :items="['Aye', 'Nay', 'Abstain']"
                  outlined
                ></v-autocomplete>

                <v-btn rounded color="primary">submit vote</v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mt-3 text-capitalize">
        <v-card-title>
          <p>documents pertaining this session</p>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="item in 5"
              :key="item"
              link
              @click="pdfDialog = true"
            >
              <v-list-item-icon>
                <v-icon> mdi-file-pdf-box </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> document {{ item }} </v-list-item-title>
                <v-list-item-subtitle>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Aliquam error aut laudantium similique ipsum facere
                  perspiciatis commodi labore itaque consequuntur. Eius
                  dignissimos fugiat voluptate nemo, possimus repudiandae
                  similique mollitia? Fugiat?
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </div>
    <v-dialog v-model="pdfDialog">
      <pdf-viewer />
    </v-dialog>
  </div>
</template>

<script>
import PdfViewer from "../components/PdfViewer.vue";
export default {
  components: { PdfViewer },
  data() {
    return {
      pdfDialog: false,
      runtimeTranscription_: "",
      transcription_: [],
      lang_: "en-US",
      doneTalking: false,
    };
  },
  methods: {
    record() {
      window.SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognition = new window.SpeechRecognition();
      recognition.lang = this.lang_;
      // recognition.interimResults = true;
      // recognition.continuous = true;
      recognition.addEventListener("result", (event) => {
        var text = Array.from(event.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");
        this.runtimeTranscription_ = this.runtimeTranscription_ + " " + text;
      });

      recognition.start();

      recognition.addEventListener("end", () => {
        console.log("stopping");
        if (this.doneTalking) {
          this.transcription_.push(this.runtimeTranscription_);
          this.runtimeTranscription_ = "";
          recognition.stop();
          console.log(this.transcription_);
        } else {
          recognition.start();
        }
      });
    },
  },
};
</script>

<style></style>
